<template>
  <div>
    <b-modal
      :id="id"
      size="xl"
      title="Đồng bộ doanh nghiệp"
      cancel-title="Hủy bỏ"
      cancel-variant="outline-primary"
      :ok-only="true"
      ok-variant="outline-primary"
      ok-title="Hủy"
      @hide="$emit('hideModalAsyncBusiness')"
    >

      <div class="d-flex justify-content-end button-header ml-1 ">
        <!-- <div
          id="btn-all-header"
          class="d-flex"
        >
          <button-component
            v-b-tooltip.hover.top="'Đồng bộ'"
            class="button-icon mr-1"
            content-btn=""
            variant="primary"
            icon-btn="RefreshCcwIcon"
            :disabled="!showBtnSync"
            @click="getListBusinessSync"
          />
        </div> -->
        <div class="header-action">
          <button-component
            :content-btn="!showBtnSync? 'Đồng bộ tất cả':'Đồng bộ các lựa chọn'"
            variant="primary"
            class="m-0"
            icon-btn="RefreshCcwIcon"
            @click="handlerSyncBusiness(!showBtnSync ? 1 : 2)"
          />
        </div>
      </div>
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="rows || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column:Tên doanh nghiệp-->
          <span
            v-if="props.column.field === 'name'"
          >
            <span class="text-name">{{ props.row.name }}</span>
          </span>

          <!-- Column: Mã số thuế-->
          <span v-else-if="props.column.field === 'taxNumber'">
            <span class="text-nowrap">{{ props.row.taxNumber }}</span>
          </span>

          <!-- Column: Trạng thái-->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusSyncBusiness(props.row.status, props.row).variant">
              <span>{{ statusSyncBusiness(props.row.status, props.row).content }}</span>
            </b-badge>
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'action'">
            <span @click="showModalDetail(props.row)">
              <feather-icon
                v-b-tooltip.hover.top="'Xem chi tiết'"
                icon="EyeIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              v-if="statusSyncBusiness(props.row.status, props.row).variant !== 'success'"
              @click="handlerSyncBusiness(3, props.row)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Đồng bộ doanh nghiệp'"
                icon="RefreshCcwIcon"
                size="18"
                class="text-body ml-1"
              />
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <detail-sync-business
        :id="idModalDetail"
        :rows="dataDetail"
      />
      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        title="Đồng bộ doanh nghiệp"
        :content="modalContent"
        @accept="handlerSync(checkStatusSync)"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBTooltip, BBadge } from 'bootstrap-vue'
import { formatDateToDDMM } from '@core/utils/filter'

export default {
  components: {
    BModal,
    BBadge,
    MyPagination: () => import('@/components/pagination/MyPagination.vue'),
    VueGoodTable: () => import('@/components/table/VueGoodTable.vue'),
    DetailSyncBusiness: () => import('./DetailSyncBusiness.vue'),
    ButtonComponent: () => import('@/components/button/ButtonComponent.vue'),
    ConfirmModal: () => import('@/components/confirm-modal/ConfirmModal.vue'),
  },
  directives: {
    Ripple: () => import('vue-ripple-directive'),
    'b-tooltip': VBTooltip,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    rows: {
      type: Array,
      default: _ => [],
    },
    totalRecord: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modalContent: '',
      confirmModalId: 'confirmSyncBusiness',
      idModalDetail: 'modal-detail-sync-business',
      columns: [
        {
          label: 'Tên doanh nghiệp',
          field: 'name',
        },
        {
          label: 'Mã số thuế',
          field: 'taxNumber',
        },
        {
          label: 'Trạng thái',
          field: 'status',
        },
        {
          label: 'Chức năng',
          field: 'action',
          width: '150px',
        },
      ],
      dataRows: [],
      urlQuery: {
        pageNumber: 1,
        pageSize: 10,
      },
      dataDetail: [
        {
          name: 'Tên Doanh Nghiệp',
          code: 'name',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Tên Doanh Nghiệp Tiếng Anh',
          code: 'englishName',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Tên Doanh Nghiệp Viết Tắt',
          code: 'abbreviationName',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Mã số thuế',
          code: 'taxNumber',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Số quyết định thành lập',
          code: 'decisionEstablish',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Đơn vị cấp quyết định thành lập',
          code: 'unitIssued',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Ngày cấp quyết định thành lập',
          code: 'dateIssued',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Nơi cấp quyết định thành lập',
          code: 'locationIssued',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Ngày hết hạn giấy phép kinh doanh',
          code: 'dateExpired',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Loại hình doanh nghiệp',
          code: 'bussinessType',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Ngành nghề kinh doanh chính',
          code: 'sectorOfBussiness',
          isSectorBussinessExist: false,
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Địa chỉ',
          code: 'address',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Website',
          code: 'website',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Số điện thoại',
          code: 'phoneNumber',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Fax',
          code: 'fax',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
        {
          name: 'Email',
          code: 'email',
          systemBusiness: '',
          affiliatedBusiness: '',
        },
      ],
      selectItems: [],
      showBtnSync: false,
      checkStatusSync: false,
    }
  },

  methods: {
    formatDateToDDMM,
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.$emit('pageChange', { pageNumber, pageSize })
    },

    statusSyncBusiness(status, row) {
      if (status === 'None') {
        return {
          variant: 'secondary',
          content: 'Doanh nghiệp chưa có trong hệ thống',
        }
      }
      const nameVariable = [
        'name',
        'englishName',
        'abbreviationName',
        'decisionEstablish',
        'taxNumber',
        'unitIssued',
        'dateIssued',
        'locationIssued',
        'dateExpired',
        'bussinessType',
        'address',
        'website',
        'phoneNumber',
        'fax',
        'email',
      ]
      const statusAsync = []
      let data
      let dataLisa
      nameVariable.forEach(element => {
        if (['dateIssued', 'dateIssued'].includes(element)) {
          data = this.getDate(row[element]) || ''
          dataLisa = this.getDate(row[`${element}Lisa`]) || ''
        } else {
          data = row[element] ? row[element] : ''
          dataLisa = row[`${element}Lisa`] ? row[`${element}Lisa`] : ''
        }
        statusAsync.push(data === dataLisa)
      })
      if (statusAsync.every(element => element === true)) {
        return {
          variant: 'success',
          content: 'Doanh nghiệp đã được đồng bộ',
        }
      }
      return {
        variant: 'warning',
        content: 'Doanh nghiệp chưa được đồng bộ',
      }
    },

    getDate(date) {
      const time = date.split('T')
      return time[0]
    },

    async showModalDetail(data) {
      this.dataDetail.forEach(element => {
        if (element.code === 'sectorOfBussiness') {
          element.isSectorBussinessExist = !data.isSectorBussinessExist
          const temps = data[element.code].split(':')
          // eslint-disable-next-line no-undef, prefer-destructuring
          element.affiliatedBusiness = temps[1]
          element.systemBusiness = data[`${element.code}Lisa`]
        } else if (element.code === 'dateExpired' || element.code === 'dateIssued') {
          element.affiliatedBusiness = formatDateToDDMM(data[element.code])
          element.systemBusiness = formatDateToDDMM(data[`${element.code}Lisa`])
        } else {
          element.affiliatedBusiness = data[element.code]
          element.systemBusiness = data[`${element.code}Lisa`]
        }
      })
      await this.$bvModal.show(this.idModalDetail)
    },

    // chọn hàng table
    selectRowTable(val) {
      this.selectItems = val.selectedRows
      this.showBtnSync = this.selectItems.length > 0
    },

    handlerSyncBusiness(val, item) {
      this.checkStatusSync = val
      switch (val) {
        case 1:
          this.modalContent = 'Bạn chắc chắn muốn đồng bộ tất cả doanh nghiệp không?'
          this.$bvModal.show(this.confirmModalId)
          break
        case 2:
          this.modalContent = 'Bạn có muốn đồng bộ các doanh nghiệp đã chọn không?'
          this.$bvModal.show(this.confirmModalId)
          break
        case 3:
          this.modalContent = 'Bạn có muốn đồng bộ doanh nghiệp đã chọn không?'
          this.$bvModal.show(this.confirmModalId)
          this.selectItems = [item]
          break

        default:
          break
      }
    },

    handlerSync(val) {
      switch (val) {
        case 1:
          this.$emit('syncAllBusiness')
          break
        case 2:
        case 3:
          this.syncBusiness(this.selectItems)
          break

        default:
          break
      }
    },

    getListBusinessSync() {
      this.modalContent = 'Bạn có muốn đồng bộ các doanh nghiệp đã chọn không?'
      this.$bvModal.show(this.confirmModalId)
    },

    syncBusiness(list) {
      this.$emit('syncListBusiness', list)
    },
  },
}
</script>

<style lang="scss">

</style>
